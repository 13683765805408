<script>
  import Base from '@backend/Base.vue';
  import draggable from 'vuedraggable'
  import BOGen from '@helper/BOGen';
  // const $ = global.jQuery

  export default {
    extends: Base,
    components: {
      draggable,
    },
    data() {
      return {
        Name: "ProjectProgress",
        data: [],
        fac: {},
        progCat: {},
        input: {},
      }
    },
    computed: {

    },
    mounted() {
      this.$set(this.$root, 'page', this)
      this.refreshData()
      console.log(this.showArticle)
    },
    methods: {
      endDrag() {
        BOGen.apirest('/' + this.Name, {
          data: this.data,
          type: 'sortProg'
        }, (err, resp) => {
          console.log(resp)
        }, "POST");
      },
      editProg(v) {
        $('#collapseProg').addClass('show')
        $("html, body").animate({
          scrollTop: $("#collapseProg").offset().top - 100
        }, 750);
        this.fac = v
        this.fac.type = 'editProg'
      },
      addProg() {
        $('#collapseProg').addClass('show')
        this.fac = {
          mpp_is_active: 'Y',
          type: 'addProg'
        }
      },
      deleteProg(id) {
        var v = id
        swal({
          title: "Are you sure to delete " + this.ObjectName + "?",
          text: "",
          icon: "warning",
          buttons: ["No, Cancel!", "Yes, Sure!"],
          dangerMode: true,
        }).then((ok) => {
          if (ok) {
            BOGen.apirest("/" + this.Name, {
              type: "deleteProg",
              id: v
            }, () => {
              swal("Deleted!", "Information has been deleted.", "success");
              this.refreshData()
            }, "POST")
          }
        });
      },
      submitFormFac(e, callback) {
        if (e && e.btnLoading()) return;
        this.fac.mpp_project = App.$route.params.id
        BOGen.apirest("/" + this.Name, this.fac, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger", 1000, '.info-fac')
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            setTimeout(() => {
              $('[href="#collapseProg"]').click();
              this.refreshData()
            }, 2000)
            return Gen.info(resp.message, "success", 1000, '.info-fac').then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
            })
          }
        }, "POST")
      },
      submitCategory(e, callback) {
        if (e && e.btnLoading()) return;
        this.input.mppc_project = App.$route.params.id
        this.input.type = "AddCategory"
        BOGen.apirest("/" + this.Name, this.input, (err, resp) => {
          if (e) e.btnUnloading()
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                $("[error=" + k + "]").html("<label class='error'>" + err.responseJSON[k][0] + "</label>")
              })
            }
            return
          }
          if (resp.error) {
            if (this.errorFormCallback) return this.errorFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "danger", 1000, '.info-modal')
          }
          if (resp.success) {
            if (this.successFormCallback) return this.successFormCallback(resp);
            if (callback) return callback(resp);
            return Gen.info(resp.message, "success", 1000, '.info-modal').then(() => {
              this.$router.push({
                name: this.Name,
                params: {
                  id: this.$route.params.id
                }
              })
              this.progCat = resp.data
              this.input = {}
              $('#cateModal').modal('hide');
            })
          }
        }, "POST")
      },
      addCat() {
        $('#cateModal').modal('show');
      }
    },
    watch: {}

  };
</script>
<template>
  <div class="container-fluid">
    <PageTitle></PageTitle>
    <div class="topnavBackoffice">
      <router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}">Beranda</router-link>
      <router-link :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
      <router-link :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tentang Kami</router-link>
      <a href="javascript:;" class="dropdown_bo active">Artikel & Progres
        <ul class="dropdown_menu">
          <li>
            <router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link>
          </li>
          <li>
            <router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link>
          </li>
        </ul>
      </a>
      <router-link :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
    </div>

    <div class="row">
      <div class="col-md-12">
        <!-- Facilitas -->
        <div class="card">
          <div class="card-body">
            <h5 class="card-title">Development Progress</h5>
            <div class="row">

              <div class="col-md-3" :id="'dat'+vf.as_id" v-for="(vf,kf) in data" :key="kf">
                <div class="wrap_slider_img">
                  <img :src="uploader(vf.mpp_img)" class="img-responsive" />
                  <div class="slider_name">
                    <p>{{vf.mpp_title}}</p>
                  </div>
                  <a data-toggle="collapse" @click="editProg(vf)" href="javascript:;" class="bullet_edit"><i
                      class="fas fa-pencil-alt"></i></a>
                  <a href="javascript:;" @click="deleteProg(vf.mpp_id)" class="bullet_delete"><i
                      class="far fa-trash-alt"></i></a>
                  <span class="label label-success" v-if="vf.mpp_is_active == 'Y'">Active</span>
                  <span class="label label-danger" v-else>Inactive</span>
                </div>
              </div>
              <div class="col-md-2">
                <a class="wrap_upload" data-toggle="collapse" @click="addProg" href="javascript:;" role="button"
                  aria-expanded="false" aria-controls="collapseProg">
                  <div class="ic_wrap">
                    <i class="fas fa-plus"></i>
                    <p>New Progress</p>
                  </div>
                </a>
              </div>
            </div>

            <VForm @resp="submitFormFac" method="post">
              <div class="row collapse mt-4" id="collapseProg">
                <div class="col-12 info-fac"></div>
                <div class="col-md-4 mb-3">
                  <BoField name="mpp_img" mandatory>
                    <Uploader :param="{thumbnail:true}" name="mpp_img" v-model="fac.mpp_img" type="progress"
                      uploadType="upload"></Uploader>
                  </BoField>
                  <BoField name="mpp_img_alt" v-model="fac.mpp_img_alt"></BoField>
                </div>
                <div class="col-md-8 mb-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="panel_group">
                        <div class="panel_heading">Content</div>
                        <div class="panel_body">
                          <div class="form-row align-items-center">
                            <div class="col-md-9">
                              <BoField name="mpp_cat">
                                <select2 :options="progCat" :object="['mppc_id','mppc_title']" v-model="fac.mpp_cat">
                                  <option value="">-- Select Category --</option>
                                </select2>
                              </BoField>
                            </div>
                            <div class="col-md-3">
                              <a href="javascript:;" @click="addCat" class="btn btn-info my-3"><i
                                  class="icon icon-plus"></i> Category</a>
                            </div>
                            <div class="col-md-9">
                              <BoField name="mpp_title" v-model="fac.mpp_title" mandatory></BoField>
                              <BoField name="mpp_publish_date">
                                <DatePicker v-model="fac.mpp_publish_date" placeholder="Publish Date"></DatePicker>
                              </BoField>
                            </div>
                            <div class="col-md-9">
                              <BoField name="mpp_is_active">
                                <div class="row">
                                  <radio name="mpp_is_active" v-model="fac.mpp_is_active" option="Y"
                                    :attr="validation('mpp_is_active')">
                                    Active</radio>
                                  <radio name="mpp_is_active" v-model="fac.mpp_is_active" option="N">
                                    Inactive</radio>
                                </div>
                              </BoField>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-4 mt-3">
                      <button type="submit" class="btn btn-rounded btn-block btn-info btn-loading">Submit</button>
                    </div>
                  </div>
                </div>
              </div>
            </VForm>
          </div>
        </div>
        <!-- End Facilitas -->
      </div>
    </div>
    <div class="modal fade" id="cateModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Add Category</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <VForm @resp="submitCategory">
            <div class="modal-body">
              <div class="info-modal"></div>
              <BoField :label="'Category'" name="mppc_title" v-model="input.mppc_title" mandatory
                :attr="{required:'required'}"></BoField>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              <button type="submit" class="btn btn-info">Save changes</button>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>